import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { LiteYoutubeEmbed } from "react-lite-yt-embed";
import { Tweet } from "react-twitter-widgets";
import PageHeader from "../../components/PageHeader";
import Head from "../../components/Head";
import WideSection from "../../components/WideSection";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PageHeader title="My Story" mb={0} mdxType="PageHeader">
  <Head title="About Me – Max Stoiber (@mxstbr)" description="Who is Max Stoiber? " mdxType="Head" />
    </PageHeader>
    <p>{`For the short version, watch this beautiful mini documentary movie that folks from Honeypot made about the influence of open source on my life:`}</p>
    <WideSection css={{
      '[class*="yt-lite"]': {
        borderRadius: `5px`
      }
    }} mdxType="WideSection">
  <LiteYoutubeEmbed id="ifq3xhik8tE" noCookie mute={false} mdxType="LiteYoutubeEmbed" />
    </WideSection>
    <hr></hr>
    <p>{`For the deeper version, here is my full story:`}</p>
    <p>{`I was born and raised in Mödling, a small town just outside of Vienna in Austria 🇦🇹, with my two siblings. I discovered my love for computers in school through gaming — specifically I played a lot of Minecraft (even hosted a server for my friends).`}</p>
    <p>{`I learned my first bits of code in IT class when we had to build a “personal website” using basic HTML and CSS. I immensely enjoyed working on it, adding a guestbook and hit counter and trying all the CSS features I could find.`}</p>
    <p>{`I starting making websites in my free time for fun, at first only toys and eventually for people that wanted one made. (including `}<a parentName="p" {...{
        "href": "http://unfallchirurgin.com"
      }}>{`my mom`}</a>{`) I learned new things by trying to copy websites I liked.`}</p>
    <p>{`After graduating from the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Sir-Karl-Popper-Schule"
      }}>{`Sir Karl Popper high school`}</a>{` I thought about what I wanted to study. Computer science seemed like the obvious choice, so I enrolled in the local university.`}</p>
    <p>{`...and hated it.`}</p>
    <p>{`While I enjoyed student life, I did not enjoy studying CS. I dreaded every second I had to spend on Algebra, Analysis, technical computer science, and many other courses. I had already fallen in love with the visual and immediate nature of web development and felt like these courses had nothing to do with what I enjoyed.`}</p>
    <p>{`After a couple months of slogging through coursework, I randomly stumbled upon this tweet:`}</p>
    {
      /* <Tweet tweetId="537210432906616832" /> */
    }
    <p>{`In a moonshot attempt, I applied, thinking "What's the worst that could happen?" Miraculously, I somehow convinced them I was the best candidate and they wanted me in London for three months to learn frontend development — a dream opportunity!`}</p>
    <p>{`I told my parents that missing three months of university was no big deal, a complete lie. I figured that after the internship I would easily be able to find a job and then my parents would not be worried about me having a degree anymore.`}</p>
    <p>{`I had no idea how wrong I was, but more on that soon.`}</p>
    <p>{`The three months in London were formative. I got to work on everything from real client projects to `}<a parentName="p" {...{
        "href": "https://frankensim.animade.tv"
      }}>{`fun`}</a>{` `}<a parentName="p" {...{
        "href": "https://sloth.animade.tv"
      }}>{`toys`}</a>{` with my fellow interns. `}<a parentName="p" {...{
        "href": "https://thefwa.com/cases/frankensim"
      }}>{`We`}</a>{` `}<a parentName="p" {...{
        "href": "https://winners.webbyawards.com/2016/websites/general-websites/weird/160249/frankensim"
      }}>{`even`}</a>{` `}<a parentName="p" {...{
        "href": "https://www.awwwards.com/sites/frankensim"
      }}>{`won`}</a>{` `}<a parentName="p" {...{
        "href": "https://www.csswinner.com/details/frankensim/9805"
      }}>{`some`}</a>{` `}<a parentName="p" {...{
        "href": "https://www.pixelawards.com/winners.html#/category-experimental"
      }}>{`awards`}</a>{`! I learned a lot about HTML, CSS, JavaScript and React, having no clue that these experiences would define my future career.`}</p>
    <p>{`I came back to Vienna thinking "I am now a frontend developer!". Naturally, I continued my plan and applied for dozens of jobs at cool local companies, expecting at least one of them to hire me.`}</p>
    <p>{`I got zero good job offers.`}</p>
    <p>{`I had some offers to join other companies as an intern, but I knew that I had more skill than an intern would have and was on at least a junior level. I didn't want to work for intern-level pay anymore, I felt like I was worth more! Thankfully due to my family situation, I also did not have to work for money, so I had the luxury of being able to choose.`}</p>
    <p>{`So I kept on job hunting, building my `}<a parentName="p" {...{
        "href": "https://github.com/mxstbr/eternalpad"
      }}>{`own`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/mxstbr/sharingbuttons.io"
      }}>{`little`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/mxstbr/login-flow"
      }}>{`apps`}</a>{` on the side and also gave `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=TGwjgmAqNRo"
      }}>{`my first talk`}</a>{` at the local JavaScript meetup.`}</p>
    <p>{`After setting up webpack for the umpteenth time to build one of my apps I figured I'd make a small boilerplate for myself and push it up to GitHub so I would have a full setup ready to go for my next projects. I called it `}<a parentName="p" {...{
        "href": "https://github.com/mxstbr/react-boilerplate"
      }}>{`react-boilerplate`}</a>{`.`}</p>
    <p>{`I kept improving the boilerplate as I learned new tools (e.g. redux-saga, ImmutableJS) and writing lots of documentation of how these tools work. It garnered a solid ~50 stars on GitHub and a few folks using it, but it was mostly just me.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      